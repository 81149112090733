import { Col, Row } from "antd";
import React from "react";
import { EngineName } from "../Global.Names";
import { ImageComponent } from "../components/common/Image.component";
import { AssetLocation, LocationType } from "../model/Picture";
import { HomePageSection, HomePageSectionProps } from "./HomePage.Section";
import { WindowHelpers } from "../helpers/Window.helper";

export function HomePageCountriesPresenceSection(props: HomePageSectionProps) {
  return (
    <HomePageSection id="countries-presence">
      <div className="container">
        <Row align={"middle"} gutter={[50, 50]}>
          <Col span={WindowHelpers.isMobile() ? "24" : "12"} className="fs-3">
            We have users spread across{" "}
            <span className="fs-1 bold">150+ countries</span> and they speak{" "}
            <span className="fs-1 bold">50+ different languages</span>
          </Col>
          <Col span={WindowHelpers.isMobile() ? "24" : "12"}>
            <ImageComponent
              maxWidth={"100%"}
              image={{
                locationType: LocationType.URL,
                data: "/img/countries.jpg",
              }}
            />
          </Col>
        </Row>
      </div>
    </HomePageSection>
  );
}
