import React, { useEffect, useState } from "react";
import { OrderAPI } from "../api/OrderAPI";
import { PageProps } from "../model/views/PageProps";
import { OrderListItemComponent } from "./OrderList.component";
import { ListItemPlainLayout } from "../list/ListItem.Layout";
import { ListItemProps, ListV2 } from "../list/ListV2";
import { SearchRequest } from "../model/search/UserSearchRequestV1";
import { PageResponse } from "../paging/Paging.Model";

export interface UserOrdersPageContentProps extends PageProps {}

export function UserOrdersPageContent(props: UserOrdersPageContentProps) {
  return (
    <div className="main-container UserOrdersPageContent">
      {props.user && (
        <>
          <div>
            <h2>Your orders</h2>

            <ListV2
              onFetch={function (
                searchRequest?: SearchRequest | undefined
              ): Promise<PageResponse<any>> {
                return OrderAPI.fetchOrdersV2(
                  props.user!.basicDetails.id,
                  searchRequest?.pageParams
                ).executeV2();
              }}
              ItemLayoutComponent={ListItemPlainLayout}
              itemNode={(itemProps: ListItemProps) => {
                let order = itemProps.item;
                return <OrderListItemComponent order={order} />;
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}
