import React from "react";
import { EngineName } from "../Global.Names";
import { publicLinkConstants } from "../constants";
import { HomePageExpertCompaniesSection } from "./HomePage.ExpertCompanies.Section";
import { HomePageFaqsSection } from "./HomePage.Faqs.Section";
import { HomePageOfferingTypesSection } from "./HomePage.OfferingTypes.Section";
import { HomePageStatsSection } from "./HomePage.Stats.Section";
import { HomePageStepsSection } from "./HomePage.Steps.Section";
import { HomePageTestimonialsSection } from "./HomePage.Testimonials.Section";
import { HomePageCountriesPresenceSection } from "./HomePage.CountriesPresence.Section";

export function HomeRemaining() {
  return (
    <div>
      <div className="headerbackgrounddiv">
        <header className="masthead darkbglayer">
          <div className="">
            <div className="container">
              <div className="masthead-subheading">Connect. Learn. Grow.</div>
              <div className="masthead-heading">
                Unlock Your Potential with Expert Mentorship
              </div>
              <a
                className="btn btn-primary btn-xl text-uppercase js-scroll-trigger mx-3 mob-my-3 fs-22"
                href={publicLinkConstants.FIND_EXPERTS}
              >
                Find an {EngineName.SENTENCE_MID}
              </a>
              <a
                className="btn btn-primary btn-xl text-uppercase js-scroll-trigger fs-22"
                href={publicLinkConstants.BECOME_EXPERT}
              >
                Become an {EngineName.SENTENCE_MID}
              </a>
            </div>
          </div>
        </header>
      </div>
      <HomePageStatsSection />
      {/* <HomePageTopExpertsSection /> */}
      <HomePageOfferingTypesSection />
      <HomePageTestimonialsSection />
      <HomePageStepsSection />
      <HomePageExpertCompaniesSection />
      <HomePageFaqsSection />
      <HomePageCountriesPresenceSection />
      <section className="page-section" id="contact">
        <div className="container">
          <div className="text-center">
            <h2 className="section-heading text-uppercase">
              LEARN Together! GROW Together!
            </h2>
          </div>
          <div className="text-center mt-5">
            <a
              className="btn btn-primary btn-xl text-uppercase js-scroll-trigger"
              href={publicLinkConstants.FIND_EXPERTS}
            >
              Schedule a Session
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}
