import { Col, Row } from "antd";
import React from "react";
import { User } from "../user/User";
import { IOfferingV2, OfferingTypeName } from "./Offering";
import "./OfferingCard.css";
import { OfferingV2RedirectCard } from "./OfferingV2.Redirect.Card";

export interface OfferingCardGridProps {
  offeringsV2: IOfferingV2[];
  loggedInUser?: User;
  dl?: React.ReactNode;
  itemColXl?: number;
}

export function OfferingCardV2Grid(props: OfferingCardGridProps) {
  return (
    <div className="OfferingCardGrid">
      {props.offeringsV2.length > 0 && (
        <Row gutter={[20, 20]}>
          {props.offeringsV2.map((offering, index) => (
            <>
              {offering.commonDetails.publishedAt &&
                !offering.commonDetails.deletedAt &&
                !offering.commonDetails.isHiddenFromListing && (
                  <Col lg={12} xl={props.itemColXl} key={offering.id}>
                    {[
                      OfferingTypeName.PATH,
                      OfferingTypeName.EVENT,
                      OfferingTypeName.DIGITAL_PRODUCT,
                    ].includes(offering.commonDetails.type.typeName) && (
                      <OfferingV2RedirectCard
                        className="offering-grid-card"
                        offering={offering}
                        liu={props.loggedInUser}
                      />
                    )}

                    {offering.commonDetails.type.typeName ===
                      OfferingTypeName.VIDEO_SESSION && (
                      <OfferingV2RedirectCard
                        className="offering-grid-card"
                        offering={offering}
                        liu={props.loggedInUser}
                      />
                    )}

                    {offering.commonDetails.type.typeName ===
                      OfferingTypeName.TEXT_QUERY && (
                      <OfferingV2RedirectCard
                        className="offering-grid-card"
                        offering={offering}
                        liu={props.loggedInUser}
                      />
                    )}
                  </Col>
                )}
            </>
          ))}
        </Row>
      )}
    </div>
  );
}
