import React from "react";
import { HTMLProps } from "../model/HTMLProps";

export interface HomePageSectionProps extends HTMLProps {
  id?: string;
  children?: React.ReactNode;

  headingStartIcon?: React.ReactNode;
  heading?: React.ReactNode;
  subheading?: React.ReactNode;
}

export function HomePageSection(props: HomePageSectionProps) {
  return (
    <section
      className={`HomePageSection page-section ${props.className} mob-px-2`}
      id={props.id}
    >
      <div className="text-center">
        {props.heading && (
          <h2 className="section-heading">
            <span className="mr-4 fs-100">{props.headingStartIcon}</span>
            {props.heading}
          </h2>
        )}

        {props.subheading && (
          <h3 className="section-subheading text-muted">{props.subheading}</h3>
        )}
      </div>
      {props.children}
    </section>
  );
}
