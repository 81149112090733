import React from "react";
import { BookingList } from "../../booking/Booking.List";
import { User } from "../../user/User";

interface AdminSessionsContentProps {
  user: User;
}

export function AdminSessionsContent(props: AdminSessionsContentProps) {
  return (
    <div className="pt-4">
      <h4 className="mb-4"> Bookings </h4>
      <BookingList
        layoutProps={{
          showEngine: true,
          showBogie: true,
          showEnginePrice: true,
          showAdminActions: true,
          showGroupByAttendee: true,
        }}
      />
    </div>
  );
}
