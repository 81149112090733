import React from "react";
import { adminLinkConstants } from "../../constants";
import { PlatformDl } from "../../Global.Names";

class MyComponent extends React.Component {
  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid">
            <a className="navbar-brand" href="#">
              {PlatformDl.SENTENCE_START} Admin
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a className="nav-link" href={adminLinkConstants.BOOKINGS}>
                    Bookings
                  </a>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Payments
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a
                        className="dropdown-item"
                        href={adminLinkConstants.PAYMENTS}
                      >
                        Payments
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href={adminLinkConstants.LEDGER_BALANCES}
                      >
                        Ledger Balances
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href={adminLinkConstants.WITHDRAWALS}
                      >
                        Withdrawals
                      </a>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href={adminLinkConstants.PRRS}
                      >
                        Payment Release Requests
                      </a>
                    </li>
                  </ul>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    href={adminLinkConstants.INTERVIEW_EXPERIENCES}
                  >
                    Interview Experiences
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href={adminLinkConstants.QUESTIONS}>
                    Questions
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href={adminLinkConstants.ORDERS}>
                    Orders
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href={adminLinkConstants.USERS}>
                    Users
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href={adminLinkConstants.EXPERTS}>
                    Experts
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href={adminLinkConstants.SKILLS}>
                    Skills
                  </a>
                </li>

                <li className="nav-item">
                  <a className="nav-link" href={adminLinkConstants.COMPANIES}>
                    Companies
                  </a>
                </li>
              </ul>
              <form className="d-flex" role="search">
                <input
                  className="form-control me-2"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                />
                <button className="btn btn-outline-success" type="submit">
                  Search
                </button>
              </form>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default MyComponent;
