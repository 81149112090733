export const paymentConstants = {
  // RAZORPAY_KEY: "rzp_test_FRR76l4nq4T7Si",
  // CURRENCY: "INR",
  RAZORPAY_THEME_COLOR: "#4e73df",
};

export const commonConstants = {
  APP_NAME: "Engine Bogie",
};

export const adminLinkConstants = {
  PAYMENTS: "/admin/payments",
  WITHDRAWALS: "/admin/payments/withdrawals",
  BOOKINGS: "/admin/bookings",
  INTERVIEW_EXPERIENCES: "/admin/interview/experiences",
  QUESTIONS: "/admin/questions",
  ORDERS: "/admin/orders",
  USERS: "/admin/users",
  EXPERTS: "/admin/experts",
  PRRS: "/admin/prrs",
  LEDGER_BALANCES: "/admin/ledger/balances",
  SKILLS: "/admin/skills",
  COMPANIES: "/admin/companies",
};

export const admiApiLinkConstants = {
  INTERVIEW_EXPERIENCE_FETCH_ALL: "/api/admin/interview/experience",
  INTERVIEW_EXPERIENCE_APPROVE:
    "/api/admin/interview/experience/:interviewExperienceId/approve",
  INTERVIEW_EXPERIENCE_DISAPPROVE:
    "/api/admin/interview/experience/:interviewExperienceId/disapprove",

  USERS_ALL: "/api/admin/users",
  USER_TOP_EXPERT_TOGGLE: "/api/admin/user/{userId}/top-expert",
  USER_EXPERT_DISABLE_TOGGLE: "/api/admin/user/{userId}/expert-disable",
  API_ADMIN_PAYMENT_RELEASE_REQUESTS_ALL:
    "/api/admin/user/{userId}/payment-release-requests",
  API_ADMIN_PAYMENT_RELEASE_REQUEST_VERIFY:
    "/api/admin/user/{userId}/payment-release-request/{prrId}/verify",
  API_ADMIN_PAYMENT_RELEASE_REQUEST_REJECT:
    "/api/admin/user/{userId}/payment-release-request/{prrId}/reject",
  API_ADMIN_VIDEO_SESSION_CREATE_PRR:
    "/api/admin/video-sessions/{videoSessionId}/prr",

  API_ADMIN_LEDGER_ALL: "/api/admin/ledger",
  API_ADMIN_LEDGER_BALANCES: "/api/admin/ledger/balances",

  API_ADMIN_WITHDRAWALS_ALL: "/api/admin/payments/withdrawals",
  API_ADMIN_WITHDRAWAL_MARK_PAID:
    "/api/admin/payments/withdrawals/{withdrawalId}/paid",
};

export const publicLinkConstants = {
  EVENT_LANDING_PAGE_LINK: "/event/:slug/:eventId",
  EVENT_DASHBOARD_LINK: "/event/:slug/:eventId/dashboard",
  USER_PROFILE_OLD: "/user/:userId",
  USER_PROFILE: "/u/:userId",
  FIND_EXPERTS: "/find/experts",
  USER_PROFILE_EDIT: "/profile/edit",
  SESSION_DETAILS_LINK: "/video-session/:sessionId",
  TEXT_QUERY_DETAILS_LINK: "/text-query/:textQueryId",

  LOGIN: "/eblogin",
  HELP_LINK: "/help",
  CREDITS: "/credits",
  BECOME_EXPERT: "/become-expert",
  PRIVACY_POLICY_LINK: "/privacy-policy",
  TERMS_CONDITIONS_LINK: "/terms-conditions",
  REFUND_POLICY_LINK: "/refund-policy",
  CANCELLATION_REFUNDS_LINK: "/cancellation-refund",
  INDEPENDENCE_DAY_OFFER: "/independence-day-offer",
  // BECOME_EXPERT: "https://forms.gle/ebGg52ZMcvbtMmxm8",
  LOGOUT: "/logout",
  INTERVIEW_EXPERIENCES: "/interview/experiences",
  QUESTIONS: "/public/questions",
  QUESTION_DETAILS: "/public/question/:slug/:questionId",
  INTERVIEW_EXPERIENCE_DETAILS:
    "/interview/experience/:slug/:interviewExperienceId",
  INTERVIEW_EXPERIENCE_EDIT:
    "/interview/experience/:slug/:interviewExperienceId/edit",
  INTERVIEW_EXPERIENCE_ADD: "/interview/experiences/add",
  QUESTION_ADD: "/questions/add",
  QUESTION_EDIT: "/questions/:slug/:questionId/edit",
  INTERVIEW_LOOPS_PAGE: "/company/:companyId/interview-loops",
  SPECIFIC_INTERVIEW_LOOP_PAGE:
    "/company/:companyId/interview-loops/:interviewLoopId",
  INTERVIEW_ROUND_INTERVIEWER_PAGE:
    "/interview-round/:interviewRoundId/interviewer",
  INTERVIEW_ROUND_INTERVIEWER_FEEDBACK_PAGE:
    "/interview-round/:interviewRoundId/interviewer/feedback",
  INTERVIEW_ROUND_CANDIDATE_PAGE:
    "/interview-round/:interviewRoundId/candidate",
  INTERVIEW_ROUNDS_ALL_PAGE: "/interview-rounds",
  COMPANY_INTERVIEW_ROUNDS_ALL_PAGE: "/company/:companyId/interview-rounds",
  ORDER_DETAILS: "/order/:orderId",
  CHARCHAA: "/public/charchaa",
  CHARCHAA_POST: "/public/charchaa/post/:slug/:postId",
  CHARCHAA_POST_EDIT: "/charchaa/post/:slug/:postId/edit",
  CHARCHAA_POST_ADD: "/charchaa/post/add",
  USER_ORDERS_LIST: "/orders",
  EXPERT_STUDIO_EDIT_VIDEO_SESSIONS: "/expert/studio/video-sessions/edit",
  EXPERT_STUDIO_TEXT_QUERY: "/expert/studio/text-queries/edit",
  EXPERT_STUDIO_EDIT_DIGITAL_PRODUCT: "/expert/studio/digital-product/edit",
  EXPERT_STUDIO_GENERAL_SETTINGS: "/expert/studio/general-settings",
  EXPERT_STUDIO_SHARE: "/expert/studio/share",
  EXPERT_STUDIO_PAYMENTS: "/expert/studio/payments",
  EXPERT_STUDIO_CALENDAR: "/expert/studio/calendar",
  EXPERT_STUDIO_PROFILE: "/expert/studio/profile",
  EXPERT_STUDIO_COUPONS: "/expert/studio/coupons",
  EXPERT_STUDIO_COUPON_USAGES: "/expert/studio/coupons/:couponCode/usages",
  EXPERT_STUDIO_MANAGE_EVENTS: "/expert/studio/events/manage",
  EXPERT_STUDIO_EDIT_EVENT: "/expert/studio/event/:slug/:eventId/edit",

  DIGITAL_PRODUCT_DETAIL_LINK: "/booking/digital-product/:dpId",
};

export const expertSessionConstants = {
  EXPERT_SESSION_USER_ROLE_MENTEE: "MENTEE",
  EXPERT_SESSION_USER_ROLE_EXPERT: "EXPERT",
};

export const apiLinkConstants = {
  API_BASE: "/api",
  API_VIDEO_SESSIONS_FETCH_SINGLE: "/api/video-sessions/{sessionId}",
  API_VIDEO_SESSIONS_SINGLE_SCHEDULE_UPDATE:
    "/api/video-sessions/{sessionId}/schedule",
  API_VIDEO_SESSION_MENTEE_SCHEDULE_UPDATE:
    "/api/video-sessions/{sessionId}/mentee/schedule",
  API_VIDEO_SESSION_MENTEE_RESCHEDULE:
    "/api/video-sessions/{sessionId}/mentee/reschedule",
  API_VIDEO_SESSION_EXPERT_RESCHEDULE_REQUEST:
    "/api/video-sessions/{sessionId}/expert/reschedule/request",
  API_VIDEO_SESSION_MENTEE_RESCHEDULE_STATUS:
    "/api/video-sessions/{sessionId}/mentee/reschedule/status",

  USER_CONTRIBUTIONS_API: "/api/user/{userId}/contributions",
  API_USER_CONTRIBUTIONS_ALL: "/api/user/all/contributions/summary",
  SPECIFIC_USER_CONTRIBUTIONS_SUMMARY_API:
    "/api/user/{userId}/contributions/summary",
  SEARCH_USER_API: "/api/search/user",
  ADD_INTERVIEW_LOOP: "/api/company/{companyId}/interview-loops/",
  FETCH_ALL_INTERVIEW_LOOPS: "/api/company/{companyId}/interview-loops/",
  FETCH_INTERVIEW_LOOP:
    "/api/company/{companyId}/interview-loop/{interviewLoopId}",
  INTERVIEW_ROUND_ATTACHMENTS_ADD_OR_UPDATE:
    "/api/company/{companyId}/interview-loop/{interviewLoopId}/attachments",
  INTERVIEW_LOOP_RECRUITER_ADD:
    "/api/company/{companyId}/interview-loop/{interviewLoopId}/recruiters",
  INTERVIEW_LOOP_RECRUITER_FETCH_ALL:
    "/api/company/{companyId}/interview-loop/{interviewLoopId}/recruiters",
  INTERVIEW_LOOP_RECRUITER_DELETE:
    "/api/company/{companyId}/interview-loop/{interviewLoopId}/recruiters/{recruiterId}",
  INTERVIEW_ROUND_ADD:
    "/api/company/{companyId}/interview-loops/{interviewLoopId}/interview-rounds",
  INTERVIEW_LOOP_INTERVIEW_ROUND_FETCH_ALL:
    "/api/company/{companyId}/interview-loops/{interviewLoopId}/interview-rounds",
  INTERVIEW_ROUND_FETCH_USING_COMPANY_LOOP:
    "/api/company/{companyId}/interview-loops/{interviewLoopId}/interview-rounds/{interviewRoundId}",
  UPDATE_INTERVIEWER:
    "/api/company/{companyId}/interview-loops/{interviewLoopId}/interview-rounds/{interviewRoundId}/interviewer",
  INTERVIEW_ROUND_SCHEDULE:
    "/api/company/{companyId}/interview-loops/{interviewLoopId}/interview-rounds/{interviewRoundId}/schedule",
  INTERVIEW_ROUND_COMPLETE:
    "/api/company/{companyId}/interview-loops/{interviewLoopId}/interview-rounds/{interviewRoundId}/complete",
  INTERVIEW_ROUND_CANCEL:
    "/api/company/{companyId}/interview-loops/{interviewLoopId}/interview-rounds/{interviewRoundId}/cancel",
  INTERVIEW_ROUND_UPDATE_GUIDELINE:
    "/api/interview-rounds/{interviewRoundId}/guideline",
  INTERVIEW_ROUND_UPDATE_RECORDING:
    "/api/interview-rounds/{interviewRoundId}/recording",
  INTERVIEW_ROUND_UPDATE_INTERVIEWER_FEEDBACK:
    "/api/interview-rounds/{interviewRoundId}/interviewer/feedback",
  INTERVIEW_ROUND_GET_INTERVIEWER_FEEDBACK:
    "/api/interview-rounds/{interviewRoundId}/interviewer/feedback",
  INTERVIEW_ROUND_UPDATE_START_TIMESTAMP:
    "/api/company/{companyId}/interview-loops/{interviewLoopId}/interview-rounds/{interviewRoundId}/startTimestamp",
  INTERVIEW_ROUND_UPDATE_ATTACHMENT:
    "/api/interview-rounds/{interviewRoundId}/attachments",
  INTERVIEW_ROUND_FETCH_BY_ID: "/api/interview-round/{interviewRoundId}",
  INTERVIEW_ROUND_FETCH_ALL: "/api/interview-rounds",
  COMPANY_INTERVIEW_ROUND_FETCH_ALL:
    "/api/company/{companyId}/interview-rounds",
  EXPERT_SEARCH_API: "/api/user/expert/search",
  COURSE_EVENT_FETCH_BY_ID: "/api/course/{courseId}",
  MASTERCLASS_EVENT_FETCH_BY_ID: "/api/masterclass/{masterclassId}",
  EVENT_ORDER_CREATE: "/api/order/event",
  API_ORDERS_FETCH_ALL: "/api/orders?",

  GET_ORDER: "/api/order/{orderId}",
  ORDER_CHECKOUT: "/api/order/{orderId}/checkout",
  RAZORPAY_PAYMENT_SUCESS: "/api/payment/razorpay/success",
  FREE_PAYMENT_SUCESS: "/api/payment/free/success",
  EVENT_ATTENDEES_FETCH_ALL: "/api/event/{eventId}/attendees",
  EVENT_MESSAGES_FETCH_ALL: "/api/event/{eventId}/messages",
  EVENT_MESSAGES_ADD: "/api/event/{eventId}/messages",
  EVENT_MESSAGES_COMPLETE: "/api/event/{eventId}/complete",
  EVENT_REVIEWS_ADD_ATTENDEE: "/api/event/{eventId}/review/attendee",
  EVENT_REVIEWS_FETCH_ALL: "/api/event/{eventId}/reviews",
  EVENT_CREATE: "/api/event",
  EVENT_FETCH: "/api/event/{eventId}",
  USER_REVIEWS_RECEIVED:
    "/api/review/{userId}/received?page={page}&size={size}",
  FORM_FETCH: "/api/form/{formId}",
  FORM_DATA_SAVE: "/api/form/{formId}/data",
  FORM_SUBMIT: "/api/form/{formId}/submit",
  QUESTIONS_ADD: "/api/question",
  QUESTIONS_SEARCH: "/api/question/search",
  QUESTIONS_FETCH_SINGLE_BY_ID: "/api/question/{questionId}",
  QUESTIONS_UPDATE_SINGLE_BY_ID: "/api/question/{questionId}",

  // Offerings
  API_USER_OFFERINGS_LIST_VIEW_PROFILE:
    "/api/{userId}/offerings:list:viewProfile",

  API_VIDEO_SESSION_TYPE_LIST: "/api/video-sessions/types",
  API_OFFERING_VIDEO_SESSION_COUPON_APPLY:
    "/api/offering/video-session/{offeringId}/apply-coupon",
  API_OFFERING_TEXT_QUERY_COUPON_APPLY:
    "/api/offering/text-query/{offeringId}/apply-coupon",

  // Text Query
  API_OFFERING_TEXT_QUERY_TYPE_LIST: "/api/text-query/types",

  // Comments
  API_COMMENTS_ADD: "/api/{targetType}/{targetId}/comments",
  API_COMMENTS_UPDATE: "/api/{targetType}/{targetId}/comments/{commentId}",
  API_COMMENTS_DELETE: "/api/{targetType}/{targetId}/comments/{commentId}",
  API_COMMENTS_LIST_ALL: "/api/{targetType}/{targetId}/comments:list",

  // Views
  API_VIEWS_ADD: "/api/{targetType}/{targetId}/views",

  // Likes
  API_LIKE_TOGGLE: "/api/{targetType}/{targetId}/toggleLike",

  // Posts
  API_POST_FETCH_SINGLE_BY_ID: "/api/post/{postId}",
  API_POST_LIST_ALL: "/api/posts:list",
  API_POST_SEARCH: "/api/posts/search",
  API_POST_ADD: "/api/posts",
  API_POST_UPDATE: "/api/post/{postId}",
  API_POST_DELETE: "/api/post/{postId}",

  UPDATE_EXPERT_SETTINGS_GENERAL: "/api/user/{userId}/expert-settings/general",

  API_TOPIC_LIST: "/api/topics:list",

  API_USER_SHAREABLE_IMAGE: "/api/user/{userId}/images/shareable-image",
  API_CHECK_USERNAME: "/api/username/checkAvailable",
  API_USER_PAYMENT_RELEASE_REQUESTS:
    "/api/user/{userId}/payment-release-requests",

  API_USER_LEDGER: "/api/user/{userId}/ledger",
  API_USER_LEDGER_BALANCE_WITHDRAW:
    "/api/user/{userId}/ledger/balance/withdraw",
  API_USER_WITHDRAWALS: "/api/user/{userId}/payments/withdrawals",
  API_REVIEW_GENERATE_SHAREABLE_IMAGE:
    "/api/reviews/{reviewId}/shareable-image",
  API_REVIEW_SHAREABLE_CONTENT: "/api/reviews/{reviewId}/shareable-content",
  API_SHORT_URL_CREATE: "/api/short-url",
  API_SHORT_URL_FETCH_SINGLE: "/api/short-url/{shortUrlId}",

  API_USER_CALENDAR_SETTINGS_UPDATE: "/api/user/{userId}/calendar/settings",
  API_USER_CALENDAR_SETTINGS_FETCH: "/api/user/{userId}/calendar/settings",

  API_USER_CALENDAR_AVAILABILITY_DATE_TIMES:
    "/api/user/{userId}/calendar/availability/date-times?durationMins={durationMins}",

  API_CALENDAR_TZS_LIST_FETCH: "/api/calendar/tzs",
  API_COUNTRIES_LIST_FETCH: "/api/countries",
  API_DESIGNATION_ADD: "/api/designations",
  API_CHANNEL_ADD: "/api/channels",

  // Coupons
  API_USER_COUPONS_FETCH: "/api/user/{userId}/coupons",
  API_USER_COUPON_ADD: "/api/user/{userId}/coupons",
  API_USER_COUPON_UPDATE: "/api/user/{userId}/coupons/{couponCode}",
  API_USER_COUPON_DELETE: "/api/user/{userId}/coupons/{couponCode}",
  API_USER_COUPON_INSTANCES_FETCH:
    "/api/user/{userId}/coupons/{couponCode}/instances?statuses={statuses}",

  API_FILE_UPLOAD_BY_USER: "/api/user/{userId}/file/upload",
  API_DP_UPLOAD_BY_USER: "/api/user/{userId}/file/upload/dp",
  API_IMAGE_UPLOAD_BY_USER: "/api/user/{userId}/file/upload/image",

  API_USER_OFFERINGS: "/api/user/{userId}/offerings",
};

export const socialLinks = {
  LINKEDIN: "https://www.linkedin.com/company/enginebogie",
  FACEBOOK: "https://www.facebook.com/enginebogie",
  TWITTER: "https://twitter.com/EngineBogie",
};
