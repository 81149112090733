import React from "react";
import { OrderListItemComponent } from "../../orders/OrderList.component";
import { OrderAPI } from "../../api/OrderAPI";
import { ListItemProps, ListV2 } from "../../list/ListV2";
import { PageResponse } from "../../paging/Paging.Model";
import { SearchRequest } from "../../model/search/UserSearchRequestV1";
import { ListItemPlainLayout } from "../../list/ListItem.Layout";

export function AdminOrdersPageContent() {
  return (
    <div className="AdminOrdersPageContent main-container">
      <ListV2
        onFetch={function (
          searchRequest?: SearchRequest | undefined
        ): Promise<PageResponse<any>> {
          return OrderAPI.fetchOrdersV2(
            undefined,
            searchRequest?.pageParams
          ).executeV2();
        }}
        ItemLayoutComponent={ListItemPlainLayout}
        itemNode={(itemProps: ListItemProps) => {
          let order = itemProps.item;
          return <OrderListItemComponent order={order} />;
        }}
      />
    </div>
  );
}
