export class WindowHelpers {
  public static redirect(redirectUrl: string) {
    window.location.href = redirectUrl;
  }

  public static reload() {
    window.location.reload();
  }

  public static qParam(key: string): any {
    return new URLSearchParams(window.location.search).get(key);
  }

  public static isMobile() {
    return window.innerWidth <= 768;
  }

  public static openInNewTab(url: string) {
    window.open(url, "_blank")?.focus();
  }
}
